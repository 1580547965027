import React from 'react'
import { LoginForm, LayoutComponent } from '../../components'
import "./loginStyles.css"

export default function Login() {


    return (
        <LayoutComponent hideSidebar>
            <div className=' login-background'>
                <div className='layer '>
                    <div className='container '>
                    <divc className='centerForm'>
                    <LoginForm />
                    </divc>
                    </div>
                </div>
            </div>
        </LayoutComponent>
    )
}


