import React, { useState, useEffect } from 'react';
import { message, Space, Tag, Typography, Avatar } from 'antd';
import { LayoutComponent, TableComponent, AreYouSureModal } from '../../components'
import { http } from '../../services'
import './products.css'
import AddOrUpdateProduct from '../../Modals/AddOrUpdateProducts';

const { Title } = Typography


export default function Products() {

  const [showModal, setShowModal] = useState(false)

  const [data, setData] = useState([])
  const [deletingId, setDeletingId] = useState('')
  const [isAdding, setIsAdding] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [editingData, setEditingData] = useState([])
  const [isLoading, setIsLoading] = useState(false)


  async function getProducts() {
    const url = `product`;


    const response = await http(url);

    if (response?.code == 200) {
      setData(Array.isArray(response?.data) ? response?.data : [response.data])
    }
    else {
      message.error('Something went wrong')
    }
  }


  async function deleteProduct() {
    const url = `product?slug=${deletingId}`;
    const options = {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json'
      }
    }

    const response = await http(url, options);

    if (response?.code == 200) {
      message.success('product deleted successfully')
      await getProducts()
      setShowModal(false)
    }
    else {
      message.error('Something went wrong')
    }

  }
  async function updateProduct(data) {
    setIsLoading(true)

    const url = `product?slug=${editingData?.slug}`;
    const options = {
      method: 'PUT',
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify(data)
    }

    const response = await http(url, options);

    if (response !== undefined) {
      const newData = [...data]
      newData?.splice(newData?.findIndex(item => item.slug == editingData?.slug), 1, response?.data?.value)
      setData(newData)
      setIsLoading(false)
      message.success('Product updated successfully')
      setIsEditing(false)
    }
    else {
      message.error('Something went wrong')
      setIsLoading(false)
      setIsEditing(false)

    }
    return

  }

  async function addProduct(data) {
    setIsLoading(true)

    const formData = new FormData()
    for (let key in data) {
      formData.append(key, data[key])
    }
    const url = `product`;
    const options = {
      method: 'POST',
      headers: {
        // 'content-type': 'multipart/form-data',

      },
      body: formData
    }

    const response = await http(url, options);

    if (response?.code == 200) {
      message.success('Product Updated Successfully')
      setIsLoading(false)
      setIsAdding(false)
      await getProducts()

    }
    else {
      message.error('Something went wrong')
      setIsLoading(false)

    }

  }



  useEffect(() => {
    getProducts()
  }, [])

  const columns = [
    {
      title: 'Sno',
      key: '_id',
      render: (text, record, index) => {
        return (
          <span>{index + 1}</span>
        )
      }
    }

    ,
    {
      title: 'Image',
      dataIndex: 'image_url',
      key: 'image_url',
      render: (text, record) => {
        return <Avatar
          size={50}
          src={text}
        />
      }

    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',

    },
    {
      title: 'Category',
      dataIndex: 'categorySlug',
      key: 'categorySlug',

    },
    {
      title: 'Height',
      dataIndex: 'height',
      key: 'height',
      render: (text) => (
        <span>{text} ft</span>
      )

    },

    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (text) => (
        <span>{text}$</span>
      )

    },
    {
      title: 'Actions',
      key: 'action',
      dataIndex: 'status',
      fixed: 'right',
      render: (text, record) => (
        <Space size="middle">
          <Tag color={'green'} onClick={() => {
            setEditingData(record)
            setIsEditing(true)
          }} >Edit</Tag>
          <Tag color={'red'} onClick={() => {
            setDeletingId(record?.slug)
            setShowModal(true)
          }} >Delete</Tag>
        </Space>
      ),
    },
  ]

  return (
    <LayoutComponent>
      <div className="container">
        <Title className='heading'>Products</Title>
        <div>
          <button className={'custom-btn mb-4'} onClick={() => setIsAdding(true)}>Add</button>
        </div>

        <TableComponent columns={columns} data={data} />
      </div>
      {isEditing && <AddOrUpdateProduct show={isEditing} type={'update'} data={editingData} title="Edit Product" setShow={setIsEditing} onClick={updateProduct} getProducts={getProducts} />}

      {isAdding && <AddOrUpdateProduct show={isAdding} type={'add'} title="Add Product" setShow={setIsAdding} onClick={addProduct} getProducts={getProducts} />}

      <AreYouSureModal showModal={showModal} setShowModal={setShowModal} text={'Do you really want to delete this worker?'} onOk={() => deleteProduct()} />
    </LayoutComponent>
  )
}


