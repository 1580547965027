import React from 'react';
import ReactDOM from 'react-dom';
import App from './routes';
import 'antd/dist/antd.css'
import './index.css';


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
  ,

  document.getElementById('root')
);
