import { Modal } from 'antd'
import React from 'react'

function ModalSkeleton({show, setShow, title,children }) {
    const onClose=()=>setShow(false)
    return (
        <Modal
            visible={show}
            title={title}
            
            centered
            footer={false}
            destroyOnClose={true}
            onCancel={()=>setShow(false)}
        >
            {children}
        </Modal>
    )
}

export default ModalSkeleton