import React, { useEffect, useState } from 'react'
import { Layout, Menu } from 'antd';
import { useNavigate, useLocation } from 'react-router';
import { handleScreenChange } from '../../../helpers'
import { TiMessages } from "react-icons/ti"
import { FiUsers } from "react-icons/fi"
import { BiCategory } from "react-icons/bi"
import { TbBrandProducthunt } from "react-icons/tb"
import './sidebarStyles.css'

const { Sider } = Layout;


function SideBar() {

  const location = useLocation()?.pathname

  const [collapsed, setCollapsed] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    handleScreenChange(setCollapsed)
  }, [])

  return (
    <Sider className="sider"
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
    >
      <Menu
        className="sider-menu"
        defaultSelectedKeys={['Dashboard']}
        mode='inline'
      >
        <Menu.Item className={`${location === '/' ? 'active-menu' : 'sider-menu-item'}`} key='Home' onClick={() => navigate('/')} icon={<FiUsers size={20} />}>
          <span className='sider-menu-item-title'>
            Leads
          </span>
        </Menu.Item>
        {/* <Menu.Item className={`${location === '/leads' ? 'active-menu' : 'sider-menu-item'}`} key='workers' onClick={() => navigate('/leads')}
          icon={<AiOutlineShoppingCart size={20} />}
        >
          <span className='sider-menu-item-title'>
            Leads
          </span>
  </Menu.Item>  */}
        <Menu.Item className={`${location === '/products' ? 'active-menu' : 'sider-menu-item'}`} key='products' onClick={() => navigate('/products')} icon={<TbBrandProducthunt size={20} />}>
          <span className='sider-menu-item-title'>
            Products
          </span>
        </Menu.Item>
        <Menu.Item className={`${location === '/categories' ? 'active-menu' : 'sider-menu-item'}`} key='categories' onClick={() => navigate('/categories')} icon={<BiCategory size={20} />}>
          <span className='sider-menu-item-title'>
            Categories
          </span>
        </Menu.Item>
        <Menu.Item className={`${location === '/contact-messages' ? 'active-menu' : 'sider-menu-item'}`} key='contact-messages' onClick={() => navigate('/contact-messages')} icon={<TiMessages size={20} />}>
          <span className='sider-menu-item-title'>
            Contact Messages
          </span>
        </Menu.Item>

      </Menu>
    </Sider>
  )
}

export default SideBar