import React, { useEffect, useRef, useState } from 'react'
import classes from './classes.module.css'
import ModalSkeleton from './ModalSkeleton'
import { BsImage } from 'react-icons/bs'
import { message } from 'antd'
import { http } from '../services'

function AddOrUpdateProduct({ show, setShow, getProducts, title: modaltitle, data, type }) {
  const [title, setTitle] = useState(data?.title ? data?.title : '')
  const [price, setPrice] = useState(data?.price ? data?.price : '')
  const [image, setImage] = useState(data?.image_url ? data?.image_url : '')
  const [height, setHeight] = useState(data?.height ? data?.height : '4')
  const [categories, setCategories] = useState([])
  const [isUploading, setIsUploading] = useState(false)
  const [category, setCategory] = useState(data?.categorySlug ? data?.categorySlug : '')
  const [isLoading, setIsLoading] = useState(false)

  const inputRef = useRef(null)

  console.log({ category, categories })

  async function updateProduct() {
    setIsLoading(true)

    const url = `product?slug=${data?.slug}`;
    const options = {
      method: 'PUT',
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({ categorySlug: category, image, title, price, height })
    }

    const response = await http(url, options);

    if (response?.code == 200) {
      setIsLoading(false)
      message.success('Product updated successfully')
      await getProducts()
      setShow(false)
    }
    else {
      message.error('Something went wrong')
      setIsLoading(false)
      setShow(false)

    }

  }

  async function addProduct() {
    const body = { categorySlug: category, image, title, price, height }
    setIsLoading(true)

    const formData = new FormData()
    for (let key in body) {
      formData.append(key, body[key])
    }
    const url = `product`;
    const options = {
      method: 'POST',
      headers: {
        // 'content-type': 'multipart/form-data',

      },
      body: formData
    }

    const response = await http(url, options);

    if (response?.code == 200) {
      message.success('Product added successfully')
      await getProducts()
      setIsLoading(false)
      setShow(false)

    }
    else {
      message.error('Something went wrong')
      setIsLoading(false)

    }

  }


  async function getCategories() {
    const url = `category`;
    const response = await http(url);

    if (response?.code == 200) {
      setCategories(Array.isArray(response?.data) ? response?.data : [response.data])

    }
    else {
      message.error('Something went wrong')
    }
  }


  useEffect(() => {
    getCategories()
  }, [])
  useEffect(() => {
    if (type !== 'update') {
      setCategory(categories?.length > 0 ? categories[0].slug : "")
    }

  }, [categories])


  useEffect(() => {
    if (data !== undefined && type == 'update') {
      setTitle(data?.title)
      setPrice(data?.price)
      setImage(data?.image_url)
      setCategory(data?.categorySlug)
    }

  }, [data])
  return (
    <ModalSkeleton
      show={show}
      title={modaltitle}
      setShow={setShow}

    >

      <div className={classes.content}>
        {type !== 'update' && <div className={classes.imgBox}>
          {isUploading || image ? <img src={typeof image == 'string' && image ||
            typeof image == 'object' &&
            URL.createObjectURL(image)} alt=""
            onClick={() => {

              inputRef.current.click()
              setIsUploading(true)
            }}

          />
            :
            <div className={classes.avatar} onClick={() => {

              inputRef.current.click()
              setIsUploading(true)
            }}>
              <BsImage size={35} />
            </div>
          }
          <input type={'file'} ref={inputRef} onChange={(e) => setImage(e.target.files[0])} style={{ display: 'none' }} />
        </div>}
        <div className={classes.mb20}>
          <input type="text" placeholder={'Title'} value={title} onChange={(e) => setTitle(e.target.value)} className={classes.input} />
        </div>
        <div className={classes.mb20}>
          <input type="number" placeholder={'Price'} value={price} onChange={(e) => setPrice(e.target.value)} className={classes.input} />
        </div>
        <div className={classes.mb20}>
          <select value={height} onChange={(e) => setHeight(e.target.value)}>
            <option value={'6'}>6 ft</option>
            <option value={'4'}>4 ft</option>

          </select>
        </div>
        <div className={classes.mb20}>
          <select value={category} onChange={(e) => setCategory(e.target.value)}>
            {categories?.map(item => {
              return (
                <option value={item?.slug}>{item?.title}</option>
              )
            })}

          </select>
        </div>
        <div>
          <button className='custom-btn' disabled={isLoading} onClick={() => {
            type == 'update' ? updateProduct() : addProduct()
          }}> {isLoading ? "Submiting..." : "Submit"} </button>
        </div>
      </div>

    </ModalSkeleton>

  )
}

export default AddOrUpdateProduct

// </div>
