import React, { useState, useEffect } from 'react'
import { Tag, Space, message, Typography } from 'antd';
import { LayoutComponent, TableComponent, AreYouSureModal } from '../../components'
import { http } from '../../services'

const { Title } = Typography

export default function Leads() {

  const [data, setData] = useState()
  const [isDeleting, setIsDeleting] = useState('')
  const [showModal, setShowModal] = useState(false)

  async function getLeads() {
    const url = `lead`;

    const response = await http(url);

    if (response?.code == 200) {
      setData(Array.isArray(response?.data) ? response?.data : [response.data])
    }
    else {
      message.error("Something went wrong")
    }

  }

  async function deleteLead() {
    const url = `lead?slug=${isDeleting}`;
    const options = {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json'
      }
    }

    const response = await http(url, options);

    if (response?.code == 200) {
      const newData = [...data]
      newData?.splice(newData?.findIndex(x => x.slug == isDeleting), 1)
      setData(newData)

      message.success('Lead deleted successfully')
      setShowModal(false)
    }
    else {
      message.error('Something went wrong')
    }

  }


  useEffect(() => {
    getLeads()

  }, [])

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phoneNo',
      key: 'phoneNo',
    },
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      render: (text) => (
        <div>
          <p>Title: {text?.title}</p>
          <p>Height: {text?.height}</p>
          <p>Price: {text?.price}</p>
        </div>
      )
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      render: (text) => (
        <div>
          <p>{text?.title}</p>
        </div>
      )
    },
    {
      title: 'Fence Length',
      dataIndex: 'length',
      key: 'length',
      render: (text) => (
        <div>
          <p>{text} ft</p>
        </div>
      )
    },
    {
      title: 'No Of Gates',
      dataIndex: 'noOfGates',
      key: 'noOfGates',
    },

    {
      title: 'Total Amount',
      dataIndex: '',
      key: '',
      render: (text, record) => {
        const gatePrice = parseInt(record?.gate?.price) * parseInt(record?.noOfGates)
        const fencePrice = parseInt(record?.product?.price) * parseInt(record?.length)
        const finalPrice = gatePrice + fencePrice
        return (<div>
          <p>{finalPrice} $</p>
        </div>
        )
      }
    },


    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   key: 'status',
    //   render: (text, record) => (
    //     <Space size="middle">
    //       <Tag style={{ color: text === "ACTIVE" ? 'green' : 'red' }}>{text}</Tag>
    //     </Space>
    //   ),
    // },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'status',
      render: (text, record) => (
        <Space size="middle">
          <Tag color={'red'} onClick={() => {
            setIsDeleting(record?.slug)
            setShowModal(true)
          }} >Delete</Tag>
        </Space>

      ),
    },
  ]

  return (
    <LayoutComponent>
      <div className="container">

        <Title className='heading'>Leads</Title>
        <TableComponent columns={columns} data={data} />
        <AreYouSureModal showModal={showModal} setShowModal={setShowModal} text={'Do you really want to delete this lead?'} onOk={() => deleteLead()} />
      </div>
    </LayoutComponent >
  )
}
