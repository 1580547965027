import React, { useEffect, useRef, useState } from 'react'
import classes from './classes.module.css'
import { message } from 'antd'
import ModalSkeleton from './ModalSkeleton'
import { BsImage } from 'react-icons/bs'
import { http } from '../services'

function AddOrUpdateCategory({ show, setShow, title: modaltitle, data, getCategories, type }) {
  const [title, setTitle] = useState(data?.title ? data?.title : '')
  const [image, setImage] = useState(data?.image_url ? data?.image_url : '')
  const [isLoading, setIsLoading] = useState(false)


  const [isUploading, setIsUploading] = useState(false)
  const inputRef = useRef(null)



  useEffect(() => {
    if (data !== undefined) {
      setTitle(data?.title)
      setImage(data?.image_url)

    }

  }, [data])


  async function updateCategory() {
    setIsLoading(true)
    const body = { title }

    const url = `category?slug=${data?.slug}`;
    const options = {
      method: 'PUT',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(body)
    }

    const response = await http(url, options);

    if (response?.code == 200) {
      setIsLoading(false)
      message.success('Category updated successfully')
      await getCategories()
      setShow(false)
    }
    else {
      message.error('Something went wrong')
      setIsLoading(false)
      setShow(false)

    }

  }

  async function addCategory() {
    setIsLoading(true)
    const body = {
      title: title,
      image: image
    }
    const formData = new FormData()
    for (let key in body) {
      formData.append(key, body[key])
    }
    const url = `category`;
    const options = {
      method: 'POST',
      headers: {
        // 'content-type': 'multipart/form-data',

      },
      body: formData
    }

    const response = await http(url, options);

    if (response?.code == 200) {
      setIsLoading(false)
      message.success('Category added successfully')
      await getCategories()
      setShow(false)

    }
    else {
      message.error('Something went wrong')
      setIsLoading(false)
      setShow(false)

    }

  }





  return (
    <ModalSkeleton
      show={show}
      title={modaltitle}
      setShow={setShow}

    >

      <div className={classes.content}>

        {type !== 'update' && <div className={classes.imgBox}>
          {isUploading || image ? <img src={typeof image == 'string' && image ||
            typeof image == 'object' &&
            URL.createObjectURL(image)} alt=""
            onClick={() => {

              inputRef.current.click()
              setIsUploading(true)
            }}

          />
            :
            <div className={classes.avatar} onClick={() => {

              inputRef.current.click()
              setIsUploading(true)
            }}>
              <BsImage size={35} />
            </div>
          }
          <input type={'file'} ref={inputRef} onChange={(e) => setImage(e.target.files[0])} style={{ display: 'none' }} />
        </div>}



        <div className={classes.mb20}>
          <input type="text" placeholder={'Title'} value={title} onChange={(e) => setTitle(e.target.value)} className={classes.input} />
        </div>
        <div>
          <button className='custom-btn' disabled={isLoading} onClick={() => {
            type == 'update' ? updateCategory() : addCategory()
          }}> {isLoading ? "Submiting..." : "Submit"} </button>

        </div>
      </div>

    </ModalSkeleton>

  )
}

export default AddOrUpdateCategory

// </div>
