import React, { useEffect, useState } from 'react';
import { Tag, Space, message, Typography, Input } from 'antd';
import { LayoutComponent, TableComponent, AreYouSureModal } from '../../components'
import { http } from '../../services'
import { useNavigate } from 'react-router';
import "./contactMessages.css"

const { Title } = Typography


export default function ContactMessages() {
  const navigate = useNavigate()


  const [showModal, setShowModal] = useState(false)
  const [deletingId, setDeletingId] = useState('')


  const [data, setData] = useState([])

  async function getContactMessages() {
    const url = `message`;

    const response = await http(url);

    if (response?.code ==200) {
      setData(Array.isArray(response?.data) ? response?.data : [response.data])
    }
    else {
      message.error('Something went wrong')
    }

  }
  async function deleteContactMessages() {
    const url = `admin/DELETE/delete-children/${deletingId}`;
    const options = {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json'
      }
    }

    const response = await http(url, options);

    if (response?.success) {
      message.success('Children deleted successfully')
      await getContactMessages()
      setShowModal(false)
    }
    else {
      message.error('Something went wrong')
    }

  }

  useEffect(() => {
    getContactMessages()
  }, [])

  const columns = [
    {
      title: 'Sno',
      key: '_id',
      render: (text, record, index) => {
        return (
          <span>{index + 1}</span>
        )
      }
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },

    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
    },
    {
      title: 'Actions',
      key: 'action',
      dataIndex: 'status',
      render: (text, record) => (
        <Space size="middle">
          <Tag color={'green'} onClick={() => navigate('/update-children', { state: record })}>Edit</Tag>
          <Tag color={'red'} onClick={() => {
            setDeletingId(record?._id)
            setShowModal(true)
          }}>Delete</Tag>
        </Space>
      ),
      fixed: 'right'
    },
  ]



  return (
    <LayoutComponent>
      <div className="container">
        <Title className='heading'>Contact Messages</Title>
        <TableComponent columns={columns} data={ data } />
      </div>
      <AreYouSureModal showModal={showModal} setShowModal={setShowModal} text={'Do you really want to delete this children?'} onOk={() => deleteContactMessages()} />
    </LayoutComponent>
  )
}


