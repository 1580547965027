import React from "react";
import { Row, Col, Form, message, Input } from "antd"
import { useNavigate } from "react-router";
import { http } from '../../../services'
import './loginFormStyles.css'


export default function LoginForm() {

    const navigate = useNavigate()

    async function onFinish(values) {
        const url = `admin/login`
        const options = {
            body: JSON.stringify({
                email: values?.email, password: values?.password
            }),
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            }
        }


        const response = await http(url, options);

        if (response?.code == 200) {
            delete response?.data[0]?.password
            window.localStorage.setItem('uuid', JSON.stringify(response?.data[0]))
            navigate('/')

        }
        else {
            message.error(response?.message || "Either Email or Password is Incorrect")
        }
    };



    return (
        <Row >
            <Col xl={{ span: 8, offset: 8 }} lg={{ span: 10, offset: 7 }} md={{ span: 12, offset: 6 }} sm={{ span: 16, offset: 4 }} xs={{ span: 24 }}>
                <div className="login-card">
                    <h1 className="login-heading">Login</h1>
                    <Form
                        className="login-form"
                        name="basic"
                        layout='vertical'
                        onFinish={onFinish}
                        scrollToFirstError
                    >
                        <Form.Item
                            label="Email"
                            name="email"
                            className="input-item"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Email!',
                                },
                            ]}
                        >

                            <Input autocomplete="off" className="input" placeholder="Enter email here" />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            className="input-item"
                            autocomplete="off"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input.Password className="input" placeholder="Enter password here" />
                        </Form.Item>

                        <Form.Item>
                            <button className="login-button" type="submit">
                                Login
                            </button>
                        </Form.Item>
                    </Form>
                </div>
            </Col>

        </Row>
    )
}