import React, { useState, useEffect } from 'react';
import { message, Space, Tag, Typography, Avatar } from 'antd';
import { LayoutComponent, TableComponent, AreYouSureModal } from '../../components'
import { useNavigate } from 'react-router';
import { http } from '../../services'
import './products.css'
import AddOrUpdateCategory from '../../Modals/AddOrUpdateCategory';

const { Title } = Typography


export default function Products() {

  const [showModal, setShowModal] = useState(false)
  const navigate = useNavigate()

  const [data, setData] = useState([])
  const [deletingId, setDeletingId] = useState('')
  const [isAdding, setIsAdding] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [editingData, setEditingData] = useState([])



  async function getCategories() {
    const url = `category`;
    const response = await http(url);

    if (response?.code == 200) {
      setData(Array.isArray(response?.data) ? response?.data : [response.data])
    }
    else {
      message.error('Something went wrong')
    }
  }


  async function deleteCategories() {
    const url = `category?slug=${deletingId}`;
    const options = {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json'
      }
    }

    const response = await http(url, options);

    if (response?.code == 200) {
      message.success('category deleted successfully')
      await getCategories()
      setShowModal(false)
    }
    else {
      message.error('Something went wrong')
    }

  }

  async function updateCategory(data) {


    const url = `category?slug=${editingData?.slug}`;
    const options = {
      method: 'PUT',
      headers: {
      },
      body: JSON.stringify(data)
    }

    const response = await http(url, options);

    if (response?.code == 200) {
      const newData = [...data]
      newData.splice(newData?.findIndex(item => item.slug == editingData?.slug), 1, editingData, 1, response?.data)
      setData(newData)
      message.success(response?.message)
      setIsEditing(false)
    }
    else {
      message.error('Something went wrong')
    }

  }

  async function addCategory(data) {


    const formData = new FormData()
    for (let key in data) {
      formData.append(key, data[key])
    }
    const url = `category`;
    const options = {
      method: 'POST',
      headers: {
        // 'content-type': 'multipart/form-data',

      },
      body: formData
    }

    const response = await http(url, options);

    if (response?.code == 200) {
      message.success(response?.message)
      await getCategories()
      setIsAdding(false)
    }
    else {
      message.error('Something went wrong')
    }

  }



  useEffect(() => {
    getCategories()
  }, [])

  const columns = [
    {
      title: 'Sno',
      key: '_id',
      render: (text, record, index) => {
        return (
          <span>{index + 1}</span>
        )
      }
    },
    {
      title: 'Image',
      dataIndex: 'image_url',
      key: 'image_url',
      render: (text, record) => {
        return text && <Avatar
          size={50}
          src={text}
        />
      }

    }
    ,
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',

    },
    {
      title: 'Actions',
      key: 'action',
      dataIndex: 'status',
      fixed: 'right',
      render: (text, record) => (
        <Space size="middle">
          <Tag color={'green'} onClick={() => {
            setEditingData(record)
            setIsEditing(true)
          }} >Edit</Tag>
          <Tag color={'red'} onClick={() => {
            setDeletingId(record?.slug)
            setShowModal(true)
          }} >Delete</Tag>
        </Space>
      ),
    },
  ]

  return (
    <LayoutComponent>
      <div className="container">
        <Title className='heading'>Categories</Title>
        <div>
          <button className={'custom-btn mb-4'} onClick={() => setIsAdding(true)}>Add</button>
        </div>

        <TableComponent columns={columns} data={data} />
      </div>
      <AddOrUpdateCategory show={isEditing} data={editingData} title="Edit Category" setShow={setIsEditing} type={'update'} getCategories={getCategories} />
      <AddOrUpdateCategory show={isAdding} getCategories={getCategories} title="Add Category" setShow={setIsAdding} />

      <AreYouSureModal showModal={showModal} setShowModal={setShowModal} text={'Do you really want to delete this worker?'} onOk={() => deleteCategories()} />
    </LayoutComponent>
  )
}


