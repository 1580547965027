import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {

    ContactMessages,
    Leads,
    Categories,
    Products


} from '../pages'
import Login from "../pages/login";
import PrivateRoute from "./privateRoute";


export default function App() {
    return (
        <Router>
            <Routes>
                <Route path='/login'
                    element={<Login />} />
                <Route path='/contact-messages'
                    element={<PrivateRoute><ContactMessages /> </PrivateRoute>} />
                <Route path='/'
                    element={<PrivateRoute><Leads /> </PrivateRoute>}
                />
                <Route path='/categories'
                    element={<PrivateRoute><Categories /> </PrivateRoute>}
                />
                <Route path='/products'
                    element={<PrivateRoute><Products /> </PrivateRoute>}
                />
            </Routes>
        </Router>
    );
}


