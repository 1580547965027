const localIpv4 = "localhost";

const LOCAL_DEV_PORT = 7000;

const isDevelopmentEnv = false;


// export const BASE_URL = `http://${localIpv4}:${LOCAL_DEV_PORT}/pwis.com/api`
// export const BASE_URL = `http://localhost:${LOCAL_DEV_PORT}/api`
export const BASE_URL = `https://vilofence.herokuapp.com/api`
