import React, { useEffect, useState } from 'react'
import { Layout } from 'antd';
import Nav from './nav'
import SideBar from './sideBar'
import MainContent from './content'
import CustomFooter from './footer'
import { CheckUser } from '../../helpers';

const { Content } = Layout

export default function LayoutComponent({ children, hideSidebar }) {

  const [user, setUser] = useState(null)

  useEffect(() => {
    setUser(CheckUser())
  }, [])

  return (

    <Layout>
      <Nav />
      <Layout>
          {!hideSidebar && <SideBar />}
        <Layout >
          <Content>
            <MainContent>
              {children}
            </MainContent>
          </Content>
          <CustomFooter />
        </Layout>
      </Layout>
    </Layout>
  )
}
